.mainDiv {
  width: 90%;
  margin: auto;
}

.titleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 10px 20px 10px;
}

.title {
  text-align: center;
}

.footer {
  text-align: right;
  margin-top: 40px;
  font-size: x-small;
}

.componentMainDiv {
  width: 100%;
}

.selectDiv {
  margin-top: 40px;
}
